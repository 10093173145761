import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3a2ac85e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8d232d78 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _5a1f13c3 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _385be2ca = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _5ae30700 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _03d26b75 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _78e7e8a4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _19cd3b95 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _561ca378 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _fbd00572 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _09d2d21d = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _9225c668 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6013d6f3 = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _46fb538d = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _2090e2a1 = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _2d0c7506 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _184caca3 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _58298730 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _5b4e6372 = () => interopDefault(import('../pages/refund/_id.vue' /* webpackChunkName: "pages/refund/_id" */))
const _5d4083b6 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _213cf99b = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _38742716 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _3a2ac85e,
    name: "index___en"
  }, {
    path: "/ka",
    component: _3a2ac85e,
    name: "index___ka"
  }, {
    path: "/lv",
    component: _3a2ac85e,
    name: "index___lv"
  }, {
    path: "/ru",
    component: _3a2ac85e,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _8d232d78,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _5a1f13c3,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _385be2ca,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _5ae30700,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _03d26b75,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _78e7e8a4,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _19cd3b95,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _561ca378,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _fbd00572,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _09d2d21d,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _9225c668,
    name: "search___en"
  }, {
    path: "/en/tickets",
    component: _6013d6f3,
    name: "tickets___en"
  }, {
    path: "/ka/cart",
    component: _8d232d78,
    name: "cart___ka"
  }, {
    path: "/ka/categories",
    component: _5a1f13c3,
    name: "categories___ka"
  }, {
    path: "/ka/cookies",
    component: _385be2ca,
    name: "cookies___ka"
  }, {
    path: "/ka/events",
    component: _5ae30700,
    name: "events___ka"
  }, {
    path: "/ka/login",
    component: _03d26b75,
    name: "login___ka"
  }, {
    path: "/ka/logout",
    component: _78e7e8a4,
    name: "logout___ka"
  }, {
    path: "/ka/payment",
    component: _19cd3b95,
    name: "payment___ka"
  }, {
    path: "/ka/profile",
    component: _561ca378,
    name: "profile___ka"
  }, {
    path: "/ka/register",
    component: _fbd00572,
    name: "register___ka"
  }, {
    path: "/ka/restore",
    component: _09d2d21d,
    name: "restore___ka"
  }, {
    path: "/ka/search",
    component: _9225c668,
    name: "search___ka"
  }, {
    path: "/ka/tickets",
    component: _6013d6f3,
    name: "tickets___ka"
  }, {
    path: "/lv/cart",
    component: _8d232d78,
    name: "cart___lv"
  }, {
    path: "/lv/categories",
    component: _5a1f13c3,
    name: "categories___lv"
  }, {
    path: "/lv/cookies",
    component: _385be2ca,
    name: "cookies___lv"
  }, {
    path: "/lv/events",
    component: _5ae30700,
    name: "events___lv"
  }, {
    path: "/lv/login",
    component: _03d26b75,
    name: "login___lv"
  }, {
    path: "/lv/logout",
    component: _78e7e8a4,
    name: "logout___lv"
  }, {
    path: "/lv/payment",
    component: _19cd3b95,
    name: "payment___lv"
  }, {
    path: "/lv/profile",
    component: _561ca378,
    name: "profile___lv"
  }, {
    path: "/lv/register",
    component: _fbd00572,
    name: "register___lv"
  }, {
    path: "/lv/restore",
    component: _09d2d21d,
    name: "restore___lv"
  }, {
    path: "/lv/search",
    component: _9225c668,
    name: "search___lv"
  }, {
    path: "/lv/tickets",
    component: _6013d6f3,
    name: "tickets___lv"
  }, {
    path: "/ru/cart",
    component: _8d232d78,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _5a1f13c3,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _385be2ca,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _5ae30700,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _03d26b75,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _78e7e8a4,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _19cd3b95,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _561ca378,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _fbd00572,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _09d2d21d,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _9225c668,
    name: "search___ru"
  }, {
    path: "/ru/tickets",
    component: _6013d6f3,
    name: "tickets___ru"
  }, {
    path: "/en/profile/tickets",
    component: _46fb538d,
    name: "profile-tickets___en"
  }, {
    path: "/ka/profile/tickets",
    component: _46fb538d,
    name: "profile-tickets___ka"
  }, {
    path: "/lv/profile/tickets",
    component: _46fb538d,
    name: "profile-tickets___lv"
  }, {
    path: "/ru/profile/tickets",
    component: _46fb538d,
    name: "profile-tickets___ru"
  }, {
    path: "/en/categories/:cid",
    component: _2090e2a1,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _2d0c7506,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _184caca3,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _58298730,
    name: "payment-hash___en"
  }, {
    path: "/en/refund/:id?",
    component: _5b4e6372,
    name: "refund-id___en"
  }, {
    path: "/en/restore/:key",
    component: _5d4083b6,
    name: "restore-key___en"
  }, {
    path: "/ka/categories/:cid",
    component: _2090e2a1,
    name: "categories-cid___ka"
  }, {
    path: "/ka/events/:eid",
    component: _2d0c7506,
    name: "events-eid___ka"
  }, {
    path: "/ka/groups/:id?",
    component: _184caca3,
    name: "groups-id___ka"
  }, {
    path: "/ka/payment/:hash",
    component: _58298730,
    name: "payment-hash___ka"
  }, {
    path: "/ka/refund/:id?",
    component: _5b4e6372,
    name: "refund-id___ka"
  }, {
    path: "/ka/restore/:key",
    component: _5d4083b6,
    name: "restore-key___ka"
  }, {
    path: "/lv/categories/:cid",
    component: _2090e2a1,
    name: "categories-cid___lv"
  }, {
    path: "/lv/events/:eid",
    component: _2d0c7506,
    name: "events-eid___lv"
  }, {
    path: "/lv/groups/:id?",
    component: _184caca3,
    name: "groups-id___lv"
  }, {
    path: "/lv/payment/:hash",
    component: _58298730,
    name: "payment-hash___lv"
  }, {
    path: "/lv/refund/:id?",
    component: _5b4e6372,
    name: "refund-id___lv"
  }, {
    path: "/lv/restore/:key",
    component: _5d4083b6,
    name: "restore-key___lv"
  }, {
    path: "/ru/categories/:cid",
    component: _2090e2a1,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _2d0c7506,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _184caca3,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _58298730,
    name: "payment-hash___ru"
  }, {
    path: "/ru/refund/:id?",
    component: _5b4e6372,
    name: "refund-id___ru"
  }, {
    path: "/ru/restore/:key",
    component: _5d4083b6,
    name: "restore-key___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _213cf99b,
    name: "events-eid-tickets___en"
  }, {
    path: "/ka/events/:eid/tickets",
    component: _213cf99b,
    name: "events-eid-tickets___ka"
  }, {
    path: "/lv/events/:eid/tickets",
    component: _213cf99b,
    name: "events-eid-tickets___lv"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _213cf99b,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _38742716,
    name: "slug___en"
  }, {
    path: "/ka/:slug",
    component: _38742716,
    name: "slug___ka"
  }, {
    path: "/lv/:slug",
    component: _38742716,
    name: "slug___lv"
  }, {
    path: "/ru/:slug",
    component: _38742716,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
