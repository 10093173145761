import { render, staticRenderFns } from "./Summary.vue?vue&type=template&id=3c70a6e4&scoped=true&"
import script from "./Summary.vue?vue&type=script&lang=js&"
export * from "./Summary.vue?vue&type=script&lang=js&"
import style0 from "./Summary.vue?vue&type=style&index=0&id=3c70a6e4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c70a6e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartPromocode: require('/home/dayan/public_html/widgetdev.ticketshop.lv/components/Cart/Promocode.vue').default})
